import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {QrcodeComponent} from './pages/qrcode/qrcode.component';

const routes: Routes = [{
  path: '', component: QrcodeComponent, data: {
    breadcrumb: false,
  }
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
