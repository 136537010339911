import {Inject, Injectable} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd';

@Injectable()
export class MessageService {

  constructor(private msg: NzMessageService ) {
  }

  showSuccess(title, message) {
    this.msg.success(message, title);
  }

  showError(title, message) {
    this.msg.error(title + ' :' + message);
  }
}
