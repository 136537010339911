import {Injectable} from '@angular/core';
import {RequestService} from './request.service';

const apiConfig = require('../config/config.api.json');

@Injectable({
  providedIn: 'root'
})
export class QrcodeService {

  constructor(public request: RequestService) {
  }

  /**
   * 批量下载 二维码
   * @param body
   */
  public batchDownload(body: any): any {
    return this.request.downLoadPost(`${apiConfig.QrcodeApi}/cos_qrcode/bulk_download`, body);
  }

  /**
   * 批量下载 太阳码
   * @param body
   */
  public batchDownloadSunCode(body: any): any {
    return this.request.downLoadPost(`${apiConfig.QrcodeApi}/sun_code/bulk_download`, body);
  }
}
