import {APP_ID, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class StorageService {

  private localStorage: Storage;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              @Inject(APP_ID) private appId: string) {
    if (isPlatformBrowser(platformId)) {
      this.localStorage = new BrowserSideLocalStorage();
    } else {
      this.localStorage = new ServerSideLocalStorage();
    }
  }

  getItem(key: string): any {
    return this.localStorage.getItem(key);
  }

  setItem(key: string, value: any): void {
    this.localStorage.setItem(key, value);
  }

  removeItem(key: string): void {
    this.localStorage.removeItem(key);
  }

  key(index: number): string | null {
    return this.localStorage.key(index);
  }

  clear(): void {
    this.localStorage.clear();
  }
}

export class BrowserSideLocalStorage implements Storage {

  length: number;

  [key: string]: any;
  [index: number]: string;

  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  setItem(key: string, data: string): void {
    localStorage.setItem(key, data);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  key(index: number): string | null {
    return localStorage.key(index);
  }

  clear(): void {
    localStorage.clear();
  }
}

export class ServerSideLocalStorage implements Storage {

  length: number;

  [key: string]: any;
  [index: number]: string;

  getItem(key: string): string | null {
    return null;
  }

  setItem(key: string, data: string): void {

  }

  removeItem(key: string): void {

  }

  key(index: number): string | null {
    return null;
  }

  clear(): void {

  }
}
