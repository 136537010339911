import {Component, ElementRef, Inject, OnInit, Renderer2} from '@angular/core';
import {StorageService} from './service/storage.service';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {NzModalService} from "ng-zorro-antd";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent  implements OnInit {
  title = 'cloud-app-qrcode';
  queryParam: any;

  constructor(el: ElementRef,
              renderer: Renderer2,
              private router: Router,
              private modalSrv: NzModalService,
              @Inject(StorageService) private localStorage,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter(evt => evt instanceof NavigationEnd))
      .subscribe(() => {
        this.modalSrv.closeAll();
        this.hiddenPreLoader();
      });
  }

  hiddenPreLoader() {
    const preloader = document.getElementsByClassName('preloader-qrcode');
    if (preloader.length > 0) {
      preloader[0].className = 'preloader-qrcode-hidden';
    }
  }
}
