import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DA_SERVICE_TOKEN, ITokenService} from '@delon/auth';
import {GoodsService} from 'src/app/service/goods.service';
import {StorageService} from 'src/app/service/storage.service';
import {NzModalService, NzMessageService} from 'ng-zorro-antd';
import {QrcodeService} from 'src/app/service/qrcode.service';
import {HttpResponse} from '@angular/common/http';


@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.less']
})
export class QrcodeComponent implements OnInit {

  // 页面加载状态
  loading = true;

  // 全选操作参数
  allChecked = false;
  indeterminate = false;
  checked = false;
  checkOptionsOne = [];

  // 商品数据
  public produceList = [];
  // 商品名或编码
  public item_name = '';

  // 分页参数
  pageNo = 1;
  pageSize = 10;
  produceListTotal = 0;

  // 下载太阳码 modal 参数
  isVisble = false;
  nzOkLoading = false;
  app_id = '';
  sun_code_with = 1280;

  nzBatchDownloadQrcodeLoading = false;

  item_id = '';

  // 测试使用参数
  private merchantId: string;
  private token: string;
  private userName: string;

  constructor(private route: ActivatedRoute,
              private goods: GoodsService,
              @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
              private nzModal: NzModalService,
              private message: NzMessageService,
              private storage: StorageService,
              private cosqrcode: QrcodeService
  ) {

  }

  ngOnInit() {
    if (this.route.snapshot.queryParams.merchantId === undefined || this.route.snapshot.queryParams.merchantId === null) {
      this.merchantId = '100000005';
      this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJlRmFzaGlvbiIsInN1YiI6IkNsb3VkIiwiZXhwIjoxNjE5MDc1ODY4LCJjbG91ZERhdGEiOnsibWVyY2hhbnRDb25maWciOiJ7XCJjb25maWdcIjogXCJcIiwgXCJwcm9kdWN0c1wiOiBbe1wicHJvZHVjdElkXCI6IFwiMVwiLCBcInByb2R1Y3RVcmxcIjogXCJodHRwOi8vYWRtaW4uY2xvdWQuZWZhc2hpb25jaGluYS5jb21cIiwgXCJwcm9kdWN0TmFtZVwiOiBcIlBvcnRhbFwifSwge1wicHJvZHVjdElkXCI6IFwiMlwiLCBcInByb2R1Y3RVcmxcIjogXCJodHRwczovL3N0b3JlLmVmYXNoaW9uY2hpbmEuY29tXCIsIFwicHJvZHVjdE5hbWVcIjogXCJTRlNcIn0sIHtcInByb2R1Y3RJZFwiOiBcIjNcIiwgXCJwcm9kdWN0VXJsXCI6IFwiaHR0cHM6Ly9vbXMuZWZhc2hpb25jaGluYS5jb21cIiwgXCJwcm9kdWN0TmFtZVwiOiBcIk9NU1wifSwge1wicHJvZHVjdElkXCI6IFwiNFwiLCBcInByb2R1Y3RVcmxcIjogXCJodHRwczovL3Btcy5lZmFzaGlvbmNoaW5hLmNvbVwiLCBcInByb2R1Y3ROYW1lXCI6IFwiUE1TXCJ9LCB7XCJwcm9kdWN0SWRcIjogXCI2XCIsIFwicHJvZHVjdFVybFwiOiBcImh0dHBzOi8vY3JtLmVmYXNoaW9uY2hpbmEuY29tXCIsIFwicHJvZHVjdE5hbWVcIjogXCJDUk1cIn0sIHtcInByb2R1Y3RJZFwiOiBcIjExXCIsIFwicHJvZHVjdFVybFwiOiBcImh0dHA6Ly92bXRvb2wudGVjaC5lZmFzaGlvbmNoaW5hLmNvbVwiLCBcInByb2R1Y3ROYW1lXCI6IFwiVk1Ub29sXCJ9LCB7XCJwcm9kdWN0SWRcIjogXCIxMlwiLCBcInByb2R1Y3RVcmxcIjogXCJodHRwczovL3NoYXJlLnRlY2guZWZhc2hpb25jaGluYS5jb21cIiwgXCJwcm9kdWN0TmFtZVwiOiBcIlNoYXJlQXBwbGljYXRpb25cIn1dfSIsIm1lcmNoYW50SUQiOjEwMDAwMDAwNSwicm9sZUlEIjoiMTMiLCJtb2JpbGUiOiIxMzUwMTk5NzEyMyIsInJvbGVOYW1lIjoi566h55CG5bGCIiwic3RhdGUiOjEsInVzZXJOYW1lIjoiQ09TIiwidXNlcklEIjo1LCJpc0NoaWxkIjowLCJhY2NvdW50Q29uZmlnIjoie1wibWVyY2hhbnRBY2NvdW50Um9sZVwiOiBcIjEzXCIsIFwibWVyY2hhbnRBY2NvdW50RW1haWxcIjogXCIyNjI1NDg3MDJAcXEuY29tXCIsIFwibWVyY2hhbnRBY2NvdW50Um9sZU5hbWVcIjogXCLnrqHnkIblsYJcIn0ifX0.o-HHLn4oA4CNvZERbfhUGW6MmxormLmO7_InPl7nqSSV3artLy3-GGhRdNKgqYztRpQk6U7UZe09uhhy83L90byvUuH7vHA9jVoQd1iuvs1EQOStmiFpdHTbI6AfnCbaPhz87QjOHc0e866BZmNqVM8mP8bO7kUyA1P_T16oqWQERy59hrFc0Wjyr8Bwgoa_hMgnQbWDEd6ouyLEeu904uon4VJkWeuRfvVWcabCe1LPDhcPzZDbS6s2K-PKGLuQtin7YFeyBdRFwgdcMU_fLwLwbg23U8ck0i5s5UgK0SDiDV7c83f6w1A41VeXMMmagMLFSDPSaSz8xSmytUIxmw';
      this.userName = 'QRCODE_TEST';
    } else {
      this.merchantId = this.route.snapshot.queryParams.merchantId;
      this.token = this.route.snapshot.queryParams.token;
      this.userName = this.route.snapshot.queryParams.userName;
    }
    this.tokenService.set({
      token: this.token,
      id: this.merchantId,
    });
    this.storage.setItem('jwt', this.token);
    this.getProductsList();
  }

  /**
   * 分页方法
   * @param event
   */
  pageIndexChang(event: number) {
    this.pageNo = event;
    this.getProductsList();
  }

  /**
   * 分页方法
   * @param event
   */
  pageSizeChang(event: number) {
    this.pageSize = event;
    this.pageNo = 1;
    this.getProductsList();
  }

  /**
   *获取商品列表
   */
  getProductsList(query = ''): void {
    const merchantId = this.tokenService.get().id;
    this.goods.getProductsList(merchantId, query, this.pageNo, this.pageSize).then(values => {
      this.produceList = values['data']['content'];
      this.produceListTotal = Number(values['data']['total']);
      this.loading = false;
    });
  }

  /**
   * 筛选条件
   */
  filterCondition(): void {
    let query = '';
    this.item_name ? query += `&item_name=${this.item_name}` : '';
    this.pageNo = 1;
    this.getProductsList(query);
  }

  /**
   * 清空条件
   */
  clearCondition(): void {
    this.item_name = '';
    this.filterCondition();
  }

  /**
   * 批量下载二维码
   */
  batchDownloadQrcode(itemId: string): void {

    const items = [];
    // 批量下载
    if (this.isEmpty(itemId)) {

      this.nzBatchDownloadQrcodeLoading = true;
      this.produceList.forEach((data, index) => {
        if (data.checked) {
          items.push({'itemId': data.itemId});
        }
      });
    }
    // 下载
    else {
      items.push({'itemId': itemId});
    }

    if (items.length <= 0) {
      this.message.create('error', '请选择要下载的资源');
      this.nzBatchDownloadQrcodeLoading = false;
      return;
    }
    const body = {items: items, merchantId: this.merchantId, userName: this.userName};
    this.cosqrcode.batchDownload(body).subscribe(response => {

      let result = JSON.parse(response.headers.get('result'));
      if (result['code'] !== 1000) {
        this.message.create('error', '网络异常');
        this.nzBatchDownloadQrcodeLoading = false;
        return;
      }

      let fileName = response.headers.get('content-disposition').replace('attachment;filename=', '');
      fileName = decodeURI(fileName);
      this.downloadFile(response, fileName);
      this.nzBatchDownloadQrcodeLoading = false;
    });
  }

  /**
   * 下载文件
   * @param data
   * @param fileName
   */
  private downloadFile(data: HttpResponse<Blob>, fileName) {
    const file = new Blob([data.body], {type: 'application/vnd.ms-excel;charset=gb2312'});
    const a = document.createElement('a');
    a.id = 'tempId';
    document.body.appendChild(a);
    a.download = fileName;
    a.href = URL.createObjectURL(file);
    a.click();
    const tempA = document.getElementById('tempId');
    if (tempA) {
      tempA.parentNode.removeChild(tempA);
    }
  }

  refreshStatus(): void {
    const allChecked = this.produceList.every(value => value.checked === true);
    const allUnChecked = this.produceList.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.produceList.forEach(data => data.checked = value);
    this.refreshStatus();
  }

  updateAllChecked(): void {
    this.indeterminate = false;
    if (this.allChecked) {
      this.checkOptionsOne = this.checkOptionsOne.map(item => {
        return {
          ...item,
          checked: true
        };
      });
    } else {
      this.checkOptionsOne = this.checkOptionsOne.map(item => {
        return {
          ...item,
          checked: false
        };
      });
    }
  }

  /**
   * 下载太阳码 取消事件
   */
  handleCancel(): void {
    this.isVisble = false;
  }

  /**
   *  显示下载 Modal
   */
  showDownloadModal(itemId) {
    this.isVisble = true;
    this.item_id = itemId;
  }

  /**
   * 批量 下载太阳码
   */
  bulkDownloadSunCode() {
    this.nzOkLoading = true;

    if (this.isEmpty(this.app_id)) {
      this.message.create('error', '请输入AppId');
      this.nzOkLoading = false;
      return;
    }

    const items = [];
    // 批量下载
    if (this.isEmpty(this.item_id)) {
      this.produceList.forEach((data, index) => {
        if (data.checked) {
          items.push({'itemId': data.itemId});
        }
      });
    }
    // 下载
    else {
      items.push({'itemId': this.item_id});
    }

    if (items.length <= 0) {
      this.message.create('error', '请选择要下载的资源');
      this.nzOkLoading = false;
      return;
    }

    const body = {appId: this.app_id, width: this.sun_code_with, items: items};
    this.cosqrcode.batchDownloadSunCode(body).subscribe(response => {

      let result = JSON.parse(response.headers.get('result'));
      if (result['code'] !== 1000) {
        this.message.create('error', '网络异常');
        this.nzOkLoading = false;
        return;
      }

      let fileName = response.headers.get('content-disposition').replace('attachment;filename=', '');
      fileName = decodeURI(fileName);
      this.downloadFile(response, fileName);
      this.nzOkLoading = false;
    });

  }

  isEmpty(obj) {
    if (typeof obj == 'undefined' || obj == null || obj == '') {
      return true;
    } else {
      return false;
    }
  }
}
