import { Injectable } from '@angular/core';
import {RequestService} from "./request.service";
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
const apiConfig = require('../config/config.api.json');

@Injectable({
  providedIn: 'root'
})
export class GoodsService {

  public categoryId:number = -1; // 类目末级id
  public categoryName:string = ''; // 类目末级value
  public activeArr = []; // 分类列表选中分类
  public fileName = ''; // 上传文件名
  public fileSize = ''; // 上传文件大小
  public fileContent = ''; // 文件内容
  constructor(public request: RequestService) { }

  /**
   * 获取类目列表
   * @param {number} category_id
   * @returns {Promise<any>}
   */
  public getCatalogCategory(category_id:number):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.request.list(`${apiConfig.CategoryApi}/catalog_category?category_id=${category_id}`).then(res => {resolve(res);});
    })
  }

  public getOneCatalogCategory(category_id:number):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.request.list(`${apiConfig.CategoryApi}/catalog_category/${category_id}`).then(res => {resolve(res);});
    })
  }

  /**
   * 获取销售属性分组
   * @param {number} category_id
   * @returns {Promise<any>}
   */
  public getAttributeSaleGroup(category_id:number):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.list(`${apiConfig.CategoryApi}/catalog_property_value/sale_group/${category_id}`).then(res => {resolve(res);});
    });
  }

  /**
   * 获取类目属性关联列表
   * @param {number} category_id
   * @returns {Promise<any>}
   */
  public getAttributeAssociation(category_id:number):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.list(`${apiConfig.CategoryApi}/catalog_category_property/${category_id}`).then(res => {resolve(res);});
    });
  }

  /**
   * 获取属性值列表（规格值）
   * @param {number} property_id
   * @returns {Promise<any>}
   */
  public getPropertyValue(propertyId:number,groupName):Promise<any>{
    return new Promise((resolve,reject) => {
      let url = `${apiConfig.CategoryApi}/catalog_property_value?property_id=${propertyId}`;
      if (groupName !== null) {
        url = url + "&sale_group="+groupName;
      }
      this.request.list(url)
        .then(res => {resolve(res);});
    });
  }

  public getPropertyValueByPageNo(propertyId:number,groupName:string = '',pageNo,pageSize):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.list(`${apiConfig.CategoryApi}/catalog_property_value?property_id=${propertyId}&sale_group=${groupName}&page_no=${pageNo}&page_size=${pageSize}`)
        .then(res => {resolve(res);});
    });
  }

  /**
   * 新增商品
   * @param parameter
   * @returns {Promise<any>}
   */
  public addItem(parameter):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.post(`${apiConfig.ItemApi}/item`,parameter).toPromise().then(res => {resolve(res);});
    });
  }

  /**
   * 发布商品
   * @param parameter
   * @returns {Promise<any>}
   */
  public publishGoods(parameter):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.post(`${apiConfig.ItemApi}/merchant_store_item`,parameter).toPromise().then(res => {resolve(res);});
    });
  }

  /**
   * 获取商品下的关联店铺
   * @param merchantId
   * @param itemId
   */
  public getStoreGoods(merchantId,itemId):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.get(`${apiConfig.ItemApi}/merchant_store_item?item_id=${itemId}&merchant_id=${merchantId}`).toPromise().then(res => {resolve(res);});
    });
  }

  public delStoreGoods(merchantId,itemId,storeId):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.delete(`${apiConfig.ItemApi}/merchant_store_item/${itemId}/${storeId}/${merchantId}`,'').toPromise().then(res => {resolve(res);});
    });
  }

  /**
   * 修改商品详情
   * @param {string} class_id
   * @param body
   * @returns {Promise<any>}
   */
  public updateGoodsInfo(class_id:string,body:any):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.put(`${apiConfig.ItemApi}/item/${class_id}`,body).toPromise().then(res => {resolve(res);});
    });
  }

  /**
   * 获取商户商品列表
   * @param merchantId
   * @returns {Promise<any>}
   */
  public getProductsList(merchantId,query,pageNo,pageSize):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.list(`${apiConfig.ItemApi}/item?merchant_id=${merchantId}${query}&page_no=${pageNo}&page_size=${pageSize}`).then(res => {resolve(res);});
    });
  }

  /**
   * 上传商品图片、视频等资源
   * @param parameter
   * @param {string} key
   * @returns {Promise<any>}
   */
  public resourceUpload(parameter:any, key:string,bucketName:string) {
    return new Promise((resolve,reject) => {
      this.request.post(`${apiConfig.resourceSpace}/file?bucketName=${bucketName}&key=${key}`,parameter).toPromise().then(res=>{resolve(res);});
    })
  }

  /**
   * 删除商品图片、视频等资源
   * @param {string} key
   * @returns {Promise<any>}
   */
  public resourceRemove(key:string,bucketName:string) {
    return new Promise((resolve,reject) => {
      this.request.delete(`${apiConfig.resourceSpace}/file/?bucketName=${bucketName}&key=${key}`,'').toPromise().then(res=>{resolve(res);});
    })
  }

  /**
   * 获取单个商品详情
   * @param item_id
   * @returns {Promise<any>}
   */
  public getGoodsInfo(item_id):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.list(`${apiConfig.ItemApi}/item/${item_id}`).then(res => {resolve(res);});
    });
  }

  /**
   * 获取单个商品SKU详情
   * @param item_id
   * @returns {Promise<any>}
   */
  public getGoodsSkuInfo(sku_id):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.list(`${apiConfig.ItemApi}/item_sku/${sku_id}`).then(res => {resolve(res);});
    });
  }

  /**
   * 获取自定义分类列表
   * @param merchant_id
   * @returns {Promise<any>}
   */
  public getCustomClass(merchant_id,class_id=null,search_text='' ):Promise<any>{
    const query = class_id !== null ? `?merchant_id=${merchant_id}&class_id=${class_id}` : `merchant/?merchant_id=${merchant_id}`;
    return new Promise((resolve,reject) => {
      this.request.list(`${apiConfig.merchantExtend}/merchant_class/${query}${search_text}`).then(res => {resolve(res);});
    });
  }

  /**
   * 获取单个自定义分类详情
   * @param class_id
   * @returns {Promise<any>}
   */
  public getCustomClassInfo(class_id ):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.list(`${apiConfig.merchantExtend}/merchant_class/${class_id}`).then(res => {resolve(res);});
    });
  }

  /**
   * 修改单个分类详情
   * @param {string} class_id
   * @param body
   * @returns {Promise<any>}
   */
  public modifyCustomClassInfo(class_id:string, body:any):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.put(`${apiConfig.merchantExtend}/merchant_class/${class_id}`,body).toPromise().then(res => {resolve(res);});
    });
  }

  /**
   * 新增自定义分类
   * @param body
   * @returns {Promise<any>}
   */
  public addCustomClass(body:any):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.post(`${apiConfig.merchantExtend}/merchant_class`,body).toPromise().then(res => {resolve(res);});
    });
  }

  /**
   * 删除商品
   * @param {string} item_id
   * @returns {Promise<any>}
   */
  public removeItem(item_id:string):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.request.delete(`${apiConfig.ItemApi}/item`,item_id).toPromise().then(res => {resolve(res);});
    })
  }

  /**
   * 修改上下架状态
   * @param {string} item_id
   * @param {string} status
   * @returns {Promise<any>}
   */
  public approveStatus(item_id:string,status:string):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.request.put(`${apiConfig.ItemApi}/item/${item_id}/approve_status/${status}`,{}).toPromise().then(res => {resolve(res);});
    })
  }

  /**
   * 自定义分类用户关联
   * @param body
   * @returns {Promise<any>}
   */
  public associatedShop(body:any):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.request.post(`${apiConfig.storeApi}/store/class/add`,body).toPromise().then(res => {resolve(res);});
    })
  }

  /**
   * 获取改价记录
   * @param {string} merchant_id
   * @returns {Promise<any>}
   */
  public getRecord(merchant_id:string):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.request.list(`${apiConfig.ItemApi}/item_sku_price_record?merchant_id=${merchant_id}`).then(res => {resolve(res);});
    })
  }

  /**
   * 新增改价记录
   * @param body
   * @returns {Promise<any>}
   */
  public addRecord(body:any):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.request.post(`${apiConfig.ItemApi}/item_sku_price_record`,body).toPromise().then(res => {resolve(res);});
    })
  }

  /**
   * 商品关联分类
   * @param body
   * @returns {Promise<any>}
   */
  public itemAssociationClass(body:any):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.request.post(`${apiConfig.ItemApi}/merchant_class_item`,body).toPromise().then(res => {resolve(res);});
    })
  }

  /**
   * 修改商品关联分类
   * @param body
   */
  public upditemAssociationClass(body:any):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.request.put(`${apiConfig.ItemApi}/merchant_class_item`,body).toPromise().then(res => {resolve(res);});
    })
  }

  /**
   * 获取分类下已管理的商品
   * @param class_id
   * @param merchant_id
   * @returns {Promise<any>}
   */
  public getClassItems(class_id,merchant_id):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.request.list(`${apiConfig.ItemApi}/merchant_class_item?class_id=${class_id}&merchant_id=${merchant_id}`).then(res => {resolve(res);});
    })
  }

  /**
   * 删除分类下关联商品
   * @param class_id
   * @param merchant_id
   * @returns {Promise<any>}
   */
  public removeClassItems(class_id,merchant_id,item_id):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.request.delete(`${apiConfig.ItemApi}/merchant_class_item/${merchant_id}/${class_id}/${item_id}`,'').toPromise().then(res => {resolve(res);});
    })
  }

  /**
   * sku级价格修改
   * @param {string} merchant_id
   * @param {string} price
   * @param {string} sku_code
   * @returns {Promise<any>}
   */
  public modifyPrice(merchant_id :string,price:string,sku_code:string):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.request.update(`${apiConfig.ItemApi}/item_sku/${merchant_id}/${sku_code}/price/${price}`,{}).toPromise().then(res => {resolve(res);});
    })
  }

  /**
   * 删除自定义分类
   * @param class_id
   * @returns {Promise<any>}
   */
  public remove_class(class_id):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.request.delete(`${apiConfig.merchantExtend}/merchant_class/`,class_id).toPromise().then(res => {resolve(res);});
    })
  }


  /**
   * read file content
   * @param {FileList} files
   */
  public changeListener(files: FileList):void{
    if(files && files.length > 0) {
      let file : File = files.item(0);
      this.fileName = file.name;
      this.fileSize = file.size.toString() + 'B';
      let reader: FileReader = new FileReader();
      reader.readAsText(file,'UTF-8');
      reader.onload = (e) => {
        let csv: string = reader.result as string;
        this.fileContent = csv;
      }
    }
  }

  /**
   * 获取商户下所有自定义分类
   * @param merchantId
   */
  public getAllCustomClass(merchantId):Promise<any>{
    return new Promise((resolve,reject) => {
      this.request.list(`${apiConfig.merchantExtend}/merchant_class/merchant/?merchant_id=${merchantId}`).then(res => {resolve(res);});
    });
  }

  public exportAllItem(merchantId){
    const url = `${apiConfig.ItemApi}/item_export/skus?merchant_id=${merchantId}`;
    return this.request.downLoadGet(url);
  }
}
