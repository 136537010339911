import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {DA_SERVICE_TOKEN, ITokenService} from '@delon/auth';
import {Router} from '@angular/router';

let headers = new HttpHeaders();

@Injectable()
export class RequestService {

  constructor(public httpClient: HttpClient,
              @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
              private router: Router) {
  }

  /**
   * Get method
   * @param url
   * @returns {Observable<Object>}
   */
  public get(url, setting = true) {
    let headers = this.setHeader();
    if (!setting) {
      headers = headers.delete('jwt');
    }
    return this.httpClient.get(url, {headers});
  }

  /**
   * Get method
   * @param url
   * @returns {Observable<Object>}
   * @deprecated 请使用Get方法，此方法不推荐
   */
  public list(url, setting = true) {
    const result = this.get(url, setting).toPromise();
    result.then(res => {
      if (res['code'] === -1002) {
        this.router.navigateByUrl(this.tokenService.login_url);
      }
    });
    return result;
  }

  /**
   * Post method
   * @param url
   * @param data
   * @returns {Observable<Object>}
   * @deprecated 请使用Post方法，此方法不推荐
   */
  public post(url, data, header = {key: '', value: ''}) {
    let headers = this.setHeader();
    if (header.key != '') {
      headers = headers.set(header.key, header.value);
    }
    return this.httpClient.post(url, data, {headers});
  }

  /**
   * Post method
   * @param url
   * @param data
   * @returns {Observable<Object>}
   * @deprecated 请使用Post方法，此方法不推荐
   */
  public save(url, data) {
    return this.post(url, data);
  }


  /**
   * Put method
   * @param url
   * @param data
   * @returns {Observable<Object>}
   */
  public put(url, data, header = {key: '', value: ''}) {
    let headers = this.setHeader();
    if (header.key != '') {
      headers = headers.set(header.key, header.value);
    }
    return this.httpClient.put(url, data, {headers});
  }

  /**
   * Put method
   * @param url
   * @param data
   * @returns {Observable<Object>}
   * @deprecated 请使用Put方法，此方法不推荐
   */
  public update(url, data) {
    return this.put(url, data);
  }

  /**
   * Delete method
   * @param url
   * @param id
   * @returns {Observable<Object>}
   */
  public delete(url, id) {
    const headers = this.setHeader();
    if (id) {
      url = `${url}/${id}`;
    }
    return this.httpClient.delete(url, {headers});
  }

  /**
   *  设置请求头
   */
  private setHeader() {
    const token = this.tokenService.get().token;
    if (token && headers.get('token') === null) {
      headers = headers.set('token', token);
    } else if (token === null && headers.get('token') !== null) {
      headers = headers.delete('token');
    }
    headers = headers.set('Authorization', 'Basic ' + btoa('efcloud:6d4d5da545908ec3a83febc5e13bab9b7caa322905116f722d215d91264d1f0c'));
    return headers;
  }

  // 捕获异常并输出
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  public downLoadGet(url) {
    let headers = this.setHeader();
    return this.httpClient.get(url, {observe: 'response', responseType: 'blob', headers: headers});
  }

  public downLoadPost(url, body) {
    let headers = this.setHeader();
    return this.httpClient.post(url, body, {observe: 'response', responseType: 'blob', headers: headers});
  }
}
